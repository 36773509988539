'use client';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import MinimizeIconCustom from '../icons/minimizeIconCustom';
import AddIconCustom from '../icons/addIconCustom';
import CheckMark from '../icons/checkMark';

export default function AccordionItem({
  text,
  index,
  setImageIndex,
  isMultiOpen,
  expandedDrawerReg,
  setExpandedDrawerReg,
  analyticsLocation,
}) {
  const { question, summary = [], bullets, endNote, options, legalSubtext, summaryHasHTML } = text;

  const [expandedDrawerFAQ, setExpandedDrawerFAQ] = useState(false);
  const [icon, setIcon] = useState(<AddIconCustom />);
  const [fontColor, setFontColor] = useState(null);

  // method to render correct icon depending on whether a panel is expanded or not
  useEffect(() => {
    if ((expandedDrawerReg || expandedDrawerFAQ) === `panel${index + 1}`) {
      setIcon(<MinimizeIconCustom />);
      setFontColor('colorTheme.contentPrimaryOnLight');
    } else {
      setIcon(<AddIconCustom />);
      setFontColor('colorTheme.contentSecondaryOnLightWeb');
    }
  }, [expandedDrawerReg, expandedDrawerFAQ, index]);

  // method to check for drawer type and expand the first regular drawer on first render
  useEffect(() => {
    if (!isMultiOpen) {
      setExpandedDrawerReg('panel1');
    }
  }, []);

  // method to find border style depending on drawer type
  const findBorder = () => {
    if (!isMultiOpen && index === 0) {
      return 'none';
    }
    return '1px solid';
  };

  // method to expand the correct panel when clicked
  const handleChange = (panel) => (event, isExpanded) => {
    if (isMultiOpen) {
      setExpandedDrawerFAQ(isExpanded ? panel : false);
    } else {
      setExpandedDrawerReg(isExpanded ? panel : false);
      setImageIndex(index);
    }
  };

  return (
    <Accordion
      expanded={(expandedDrawerReg || expandedDrawerFAQ) === `panel${index + 1}`}
      onChange={handleChange(`panel${index + 1}`)}
      data-testid='accordionItem'
      className={(expandedDrawerReg || expandedDrawerFAQ) === `panel${index + 1}` ? 'Mui-expanded' : ''}
      sx={{
        backgroundColor: 'transparent',
        margin: '0px',
        boxShadow: 'none',
        '&.Mui-expanded': {
          margin: '0px',
          padding: '0 0 20px 0',
        },
        '&.MuiAccordion-root:before': {
          height: '0px',
        },
      }}
      data-analytics-location={analyticsLocation}
      data-analytics-action={question}
    >
      <AccordionSummary
        sx={{
          borderTop: `${findBorder()}`,
          borderColor: 'colorTheme.strokePrimaryOnLightDark',
          padding: '10px 0',
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
          },
        }}
        content={{ margin: '12px 0', '&$expanded': { margin: '12px 0', background: 'orange' } }}
        expandIcon={icon}
        aria-controls={`panel${index}a-content-${question.replace(/\s+/g, '')}`}
        id={`panel${index}-${question.replace(/\s+/g, '')}a-header`}
      >
        <Typography variant='bodyMSemibold' color={fontColor} marginRight='50px'>
          <strong>{question}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails padding='0 !important' sx={{ padding: '0' }}>
        {summary.map((summaryText, i) => (
          <Box key={summaryText.replace(/\s/g, '')} py={1}>
            {summaryHasHTML ? (
              <Box
                sx={{ typography: 'bodyS' }}
                color='colorTheme.contentPrimaryOnLight'
                dangerouslySetInnerHTML={{ __html: summaryText }}
              />
            ) : (
              <Typography variant='bodyS' color='colorTheme.contentPrimaryOnLight'>
                {summaryText}
              </Typography>
            )}
            {i !== summary.length - 1 && <br />}
          </Box>
        ))}
        {bullets &&
          bullets.map((bullet, i) => (
            <Box
              display='flex'
              alignItems='center'
              gap='16px'
              margin='8px 0'
              key={bullet.replace(/\s/g, '')}
              data-testid={`bullet${i}`}
            >
              <Box>
                <CheckMark />
              </Box>
              <Typography color='colorTheme.contentPrimaryOnLight' variant='bodyS'>
                {bullet}
              </Typography>
            </Box>
          ))}
        {options &&
          options.map((option, i) => (
            <Box
              display='flex'
              flexDirection='column'
              gap='8px 16px'
              margin='8px 0 24px 0'
              key={`optionSubtitle-${option.subtitle}`}
              data-testid={`subtitle${i}`}
            >
              <Typography color='colorTheme.contentPrimaryOnLight' variant='bodySSemibold'>
                {option.subtitle}
              </Typography>
              {option.bullets.map((bullet) => (
                <Box display='flex' gap='0 8px' alignItems='start' key={bullet}>
                  <Box
                    marginTop='7px'
                    minWidth='5px'
                    minHeight='5px'
                    borderRadius='100%'
                    backgroundColor='colorTheme.contentPrimaryOnLight'
                  />
                  <Typography color='colorTheme.contentPrimaryOnLight' variant='bodyS'>
                    {bullet}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        {!!endNote && (
          <Typography
            variant='bodyS'
            color='colorTheme.contentPrimaryOnLight'
            marginTop='16px'
            display='block'
          >
            {endNote}
          </Typography>
        )}
        {!!legalSubtext && (
          <Typography
            variant='bodyXS'
            color='colorTheme.contentSecondaryOnLightWeb'
            marginTop='16px'
            display='block'
          >
            {legalSubtext}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
